<!--
 * @Author: lzh
 * @Date: 2022-07-02 18:21:15
 * @LastEditors: lzh
 * @LastEditTime: 2022-07-05 16:40:04
 * @Description: file content
-->
<template>
  <div class="edit-dev-history-box">
    <h3>項-年份</h3>
    <el-input
      v-model="configs.items[index].yyyy"
      placeholder="請輸入年份"
      @input="setValue('items')"
    ></el-input>
    <h3>項-描述</h3>
    <el-input
      v-model="configs.items[index].desc"
      placeholder="請輸入描述"
      @input="setValue('items')"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'edit-dev-history',
  props: {
    configs: {
      default() {
        return {
          items: [
            {
              yyyy: '2022年',
              desc: '現在',
            },
            {
              yyyy: '2021年',
              desc: '雲端餐飲管理系統RicePOS與會計系統 Xero完成技術對接業務拓展至新加坡',
            },
            {
              yyyy: '2020年',
              desc: '與著名物流系統、電子支付系統完成技術對接',
            },
            {
              yyyy: '2027年',
              desc: '全港首創固定碼點餐，建立會員系統 推出飲食著數APP“飯糰”',
            },
            {
              yyyy: '2013年',
              desc: '我們擁有了第一個合作夥伴',
            },
            {
              yyyy: '2012年',
              desc: '香港成立 Gingersoft',
            },
          ],
        };
      },
    },
    index: {
      default() {
        return 0;
      },
    },
  },
  methods: {
    setValue(key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>